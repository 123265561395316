<template>
  <ion-item v-if="analysis !== undefined" :lines="lines">
    <ion-label>
      <h2 class="label">
        {{ i18n.$t('tracking.analyses.analysis') }} # {{ parseInt(index) + 1 }}
      </h2>
      <h3 v-if="statusText != null && analysisItem == null" :class="['status-text', status.error ? 'error-text' : undefined]">
        {{ statusText }}
      </h3>
      <template v-if="results != null">
        <template v-for="resultGroupKey of Object.keys(results).sort()" :key="resultGroupKey">
          <template v-for="(result, resultIndex) of results[resultGroupKey]" :key="resultIndex">
            <template v-if="getResultType(result) === 'classification' && result.ai_category != null && result.probabilities != null">
              <h4>
                {{ i18n.$t('tracking.analyses.classification.before_percentage') }}
                <span class="highlight">{{ (result.probabilities[result.ai_category.descriptor] * 100).toFixed(2) }}%</span>
                {{ i18n.$t('tracking.analyses.classification.before_classification') }}
                <EncyclopediaLink :id="getEncyclopediaEntryForAICategory(result.ai_category)" :descriptor="result.ai_category.descriptor"></EncyclopediaLink>
                {{ i18n.$t('tracking.analyses.classification.after_classification') }}
              </h4>
              <!-- Only show additional ones, if this result contains not just the single probability -->
              <p v-if="Object.keys(result.probabilities).length > 1">
                {{ i18n.$t('tracking.analyses.classification.other_classifications') }}
                <table class="probability-table">
                  <template v-for="(probability, probabilityDescriptor) of result.probabilities" :key="probabilityDescriptor">
                    <tr v-if="probabilityDescriptor !== result.ai_category.descriptor"> <!-- Hide the main classification, as it is already shown above -->
                      <td><EncyclopediaLink :descriptor="probabilityDescriptor" ></EncyclopediaLink></td>
                      <td>{{ (probability * 100).toFixed(2) }}%</td>
                    </tr>
                  </template>
                </table>
              </p>
            </template>
            <template v-else-if="getResultType(result) === 'visualization'">
              <h4>{{ i18n.$t('tracking.analyses.visualization') }}</h4>
              <ion-slides v-if="getResultVisualsAtIndex(resultGroupKey, resultIndex) != null" :options="getSlideOpts(getResultVisualsAtIndex(resultGroupKey, resultIndex))" :scrollbar="getResultVisualsAtIndex(resultGroupKey, resultIndex).length > 1" :key="getResultVisualsAtIndex(resultGroupKey, resultIndex)">
                <ion-slide v-for="(media, mediaIndex) in getResultVisualsAtIndex(resultGroupKey, resultIndex)" :key="mediaIndex" @click="openGalleryModal($event, getResultVisualsAtIndex(resultGroupKey, resultIndex), mediaIndex)">
                  <MediaPreview 
                    class="preview-media"
                    :type="media.mime"
                    :mediaUrl="media.blobURL"
                    :thumbnailUrl="media.thumbnail"
                    :imposedImageUrl="(media.imposedImage != null) ? media.imposedImage.blobURL : undefined"
                    :imposedParameters="(media.imposedImage != null) ? media.imposedImage.parameters : undefined">
                  </MediaPreview>
                </ion-slide>
              </ion-slides>
            </template>
          </template>
          <hr class="separator">
        </template>
      </template>

      <h5 class="information-data" v-if="status.completed && analysisItem != null">
        <a @click="setInformationPopoverOpen(true, $event)">
          <ion-icon :icon="informationCircleOutline"></ion-icon>
          {{ i18n.$t('tracking.analyses.analysed_data_information') }}
        </a>
      </h5> <!-- TODO Show version and information about it -->
      <ion-popover
        :is-open="isInformationPopoverOpenRef"
        css-class="information-data-popover"
        :event="informationPopoverEventRef"
        @didDismiss="setInformationPopoverOpen(false)"
      >
        <ion-list class="information-data-list">
          <ion-item 
            :detail="true"
            lines="full"
            button
            class="information-data-item"
            v-for="(fieldKeyArray, fieldKeyIndex) in usedFieldKeys"
            :key="fieldKeyIndex"
            @click="scrollToFieldKey(fieldKeyArray); setInformationPopoverOpen(false);">
            <div>
              <span v-for="(fieldKeyPart, fieldKeyPartIndex) in fieldKeyArray" :key="fieldKeyPartIndex">{{ fieldKeyPart }}</span>
            </div>
          </ion-item>
        </ion-list>
      </ion-popover>
    </ion-label>
  </ion-item>
</template>

<script>
import { IonItem, IonLabel, IonSlides, IonSlide, IonIcon, IonList, IonPopover } from '@ionic/vue';
import { computed, defineComponent, ref, onMounted, onUnmounted } from 'vue';

import MediaPreview from '@/components/MediaPreview.vue';
import EncyclopediaLink from '@/components/EncyclopediaLink.vue';

import { openGallery, default as modalComponent } from '@/components/Gallery.vue';

import { informationCircleOutline } from 'ionicons/icons';

import { parseKey } from '@/utils/report';

import _ from 'lodash';

import { useI18n } from "@/utils/i18n";

export default defineComponent({
  name: 'ViewAnalysisItem',
  components: { IonItem, IonLabel, IonSlides, IonSlide, IonIcon, IonPopover, IonList, MediaPreview, EncyclopediaLink },
  props: {
    'analysis': Object,
    'analysedData': Object,
    'index': {
      type: Number,
      default: 0
    },
    'lines': {
      type: String,
      default: 'inset'
    }
  },
  emits: ['scrollToItem'],
  setup(props, { emit }) {
    const i18n = useI18n();

    const getSlideOpts = function(media){
      let multipleFiles = Array.isArray(media) && media.length > 1;

      return{
        updateOnWindowResize: true,
        resizeObserver: true,
        zoom: false,
        autoHeight: false,
        slidesPerView: 'auto',
        spaceBetween: 15,
        scrollbar: ((multipleFiles) ? {
          el: '.swiper-scrollbar',
          draggable: true,
          hide: false
        } : false),
        allowTouchMove: multipleFiles
      };
    }

    const status = computed(() => {
      if (props.analysis != null && props.analysis.status != null) {
        return props.analysis.status;
      }
      return {};
    });

    const analysisItem = computed(() => {
      if (props.analysis != null) {
        return props.analysis.item;
      }
      return undefined;
    });

    const results = computed(() => {
      if (props.analysis != null && props.analysis.item != null) {
        return _.groupBy(props.analysis.item.result, (result) => `${result.key}->${result.index}->${result.multi_file_index}`);
      }
      return undefined;
    });

    const usedFieldKeys = computed(() => {
      if (props.analysis != null && props.analysis.item != null && props.analysis.item.field_keys != null) {
        return _.map(props.analysis.item.field_keys, (fieldKey) => {
          return parseKey(fieldKey);
        });
      }
      return undefined;
    });

    //Map all visuals and apply transformations if necessary, like imposing the original image into it
    const resultVisuals = computed(() => {
      if (results.value != null) {
        return _.mapValues(results.value, (resultGroup) => {
          return _.map(resultGroup, (result) => {
            if (result.visuals != null && result.visuals.length > 0) {
              //File for imposing the visuals into. Can be null if no original file found
              let analysedFile;
              //Check if the results correspond to the original analysed data
              if (props.analysedData != null && result.key != null && result.key in props.analysedData) {
                let analysedFormObject = props.analysedData[result.key];
                if (analysedFormObject != null && analysedFormObject.value != null) {
                  //If it is multiple files, try to get the correct index
                  if (Array.isArray(analysedFormObject.value)) {
                    if (result.multi_file_index != null) analysedFile = analysedFormObject.value[result.multi_file_index];
                  } else { //Otherwise give back the value
                    analysedFile = analysedFormObject.value
                  }
                }
              }

              //If the original file is a valid file, start the transformation for all visuals and turn them into a single array again
              if (analysedFile != null && analysedFile.blobURL != null) {
                return _.map(result.visuals, (visual) => {
                  return {
                    ...analysedFile,
                    imposedImage: {
                      ...visual,
                      parameters: result.additional_parameters
                    }
                  };
                });
              }
              //Otherwise just return the unmodified visuals
              return result.visuals;
            }
            return null;
          });
        });
      }
      return null;
    });

    const getAllResultVisuals = computed(() => {
      if (resultVisuals.value != null) {
        return _.flatten(_.compact(_.flatMap(resultVisuals.value, (resultsInGroup) => resultsInGroup)));
      }
      return undefined;
    })

    const getResultVisualsAtIndex = computed(() => {
      let groupedVisuals;
      if (resultVisuals.value != null) {
        groupedVisuals = resultVisuals.value;
      }
      return function(resultGroup, resultIndex) {
        if (groupedVisuals != null) return _.get(groupedVisuals, [resultGroup, resultIndex]);
      }
    })

    const getResultType = function(result) {
      if (result != null && result['__component'] != null) {
        switch(result['__component']) {
          case 'ai-analysis.classification':
            return 'classification'
          case 'ai-analysis.pose-estimation':
            return 'pose-estimation';
          case 'ai-analysis.visualization':
            return 'visualization';

          default:
            break;
        }
      }
    }

    const statusText = computed(() => {
      if (status.value != null) {
        if (status.value.error) {
          return i18n.$t('tracking.analyses.error');
        }

        if (status.value.completed) {
          return i18n.$t('tracking.analyses.loading');
        } else if (status.value.queued) {
          return i18n.$t('tracking.analyses.queued_long');
        }
      }

      return i18n.$t('tracking.analyses.unqueued_long');
    });

    const getEncyclopediaEntryForAICategory = function(aiCategory) {
      if (aiCategory != null && Array.isArray(aiCategory.encyclopedia_entries)) {
        return aiCategory.encyclopedia_entries[0];
      }
    }

    const openGalleryModal = function(event, mediaArray, index) {
      let offset;
      let elementHeight;
      if (event != null && event.target != null){
        let elementBounds = event.target.getBoundingClientRect();

        offset = {
          x: (elementBounds.left + elementBounds.right) / 2,
          y: (elementBounds.top + elementBounds.bottom) / 2
        };

        elementHeight = elementBounds.height;
      }

      openGallery(modalComponent, mediaArray, index, {}, offset, elementHeight);
    }

    const isInformationPopoverOpenRef = ref(false);
    const informationPopoverEventRef = ref();
    
    const setInformationPopoverOpen = function(state, event) {
      if ( isInformationPopoverOpenRef.value != state ){ //Only execute change, when it needs to be changed
        informationPopoverEventRef.value = event;
        if ( state == true ){
          if ( event !== undefined ){ //Only display when it can be positioned correctly with the event
            isInformationPopoverOpenRef.value = true;
          }
        } else {
          isInformationPopoverOpenRef.value = false;
        }
      }
    }

    const handleResize = function(){
      setInformationPopoverOpen(false); //Close popover when resizing or rotating device screen for correct display
    }

    onMounted(() => {
      window.addEventListener("resize", handleResize);
    });

    onUnmounted(() => {
      window.removeEventListener("resize", handleResize);
    });

    const scrollToFieldKey = function(fieldKeyParts) {
      emit('scrollToItem', fieldKeyParts);
    }

    return { i18n, getSlideOpts, analysisItem, results, getResultType, status, usedFieldKeys, statusText, getAllResultVisuals, getResultVisualsAtIndex, getEncyclopediaEntryForAICategory, openGalleryModal, isInformationPopoverOpenRef, informationPopoverEventRef, setInformationPopoverOpen, scrollToFieldKey, informationCircleOutline }
  }
});
</script>

<style>
/*popover styles cannot be scoped!*/
.information-data-popover {
  --max-width: 90vw;
  --width: auto;
}
</style>

<style scoped>
ion-label * {
  white-space: normal;
}

ion-slides {
  margin-top: 10px;
}

ion-slide {
  height: 100%;
  width: max-content; /* Adapts the width to the preview content --> Always maximum height in ion-slides container */
  min-width: 3em;
  max-width: 10em;
  margin-bottom: 15px;
}

.label {
  font-weight: bold;
  color: var(--ion-color-primary-text);
}

.probability-table {
  margin: 10px;
}

.probability-table td {
  border: 1px solid;
  border-top: unset;
  padding: 5px 10px;
}

.probability-table tr:last-of-type td {
  border-bottom: unset;
}

.probability-table tr td:first-of-type {
  border-left: unset;
}

.probability-table tr td:last-of-type {
  border-right: unset;
}

.status-text {
  text-overflow: unset;
  height: auto;
  white-space: normal;
}

.logo {
  vertical-align: -0.2em;
  color: var(--ion-color-primary-text);
  font-size: 20px;
  margin-right: 5px;
}

.error-text {
  color: var(--ion-color-warning);
}

.highlight {
  color: var(--ion-color-primary-text);
  font-weight: bold;
}

h4 {
  margin-top: 20px;
}

.information-data a {
  color: var(--ion-color-medium);
}

.information-data ion-icon {
  position: relative;
  vertical-align: text-top;
  font-size: 1.1em;
}

.information-data-list {
  padding: 10px 0px;
}

.information-data-item:last-of-type {
  --border-width: 0 0 0 0;
}

.information-data-item div {
  display: flex;
  flex-flow: column;
  margin: 0px 5px;
  font-size: 0.9em;
}

.information-data-item div span {
  margin: 2px 0px;
}

.information-data-item div span:not(:first-of-type)::before {
  content: "\2192";
  margin: 0px 5px;
}

hr.separator {
  height: 1px;
  background: var(--ion-color-medium-light, gray);
  margin: 5px 0 10px 0;
  z-index: 2;
  position: relative;
}
</style>
